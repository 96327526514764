import React, { useState } from 'react'
import logo from "../assets/logo.svg";
import { Link } from 'react-router-dom';
import '../sass/auth.scss'
import p1 from '../assets/microphone.svg'
import p2 from '../assets/music.svg'
import p3 from '../assets/collaborator.svg'

const Signup = () => {

    const [plan, setPlan] = useState(1)

  return (
    <div className='auth-page'>

        <div className="container py-5">
            <div className="row">
                <div className="col-lg-6 auth-container">
                    <Link to={'/'} className='auth-logo'>
                        <img src={logo} alt="" />
                    </Link>

                    <h1>Create new account</h1>
                    <h6>
                        To get started, please provide us with your email address and name
                    </h6>
                    <div className='auth-form'>
                        <input type="email" className='auth-form-input' placeholder='Email' required />
                        <input type="text" className='auth-form-input' placeholder='First Name' required />
                        <input type="text" className='auth-form-input' placeholder='Last Name' required />
                        <input type="password" className='auth-form-input' placeholder='Password' required />
                        <input type="password" className='auth-form-input' placeholder='Confirm Password' required />
                    </div>

                    <div className='row mt-5 pt-4'>
                <h2 className='text-light w-100 mx-2'>Choose account type</h2>
                <div className='col-md-4 col-6 mt-4'>
                    <div className={`plan-card ${plan === 1 && 'active'}`} onClick={() => setPlan(1)}>
                        <img src={p1} alt=""/>
                        <h1>Artist</h1>
                        <p>Unlimited albums and singles for one artist</p>
                        <h6>From <span>$2.99</span> per month</h6>
                    </div>
                </div>
                <div className='col-md-4 col-6 mt-4'>
                    <div className={`plan-card ${plan === 2 && 'active'}`} onClick={() => setPlan(2)}>
                        <img src={p2} alt=""/>
                        <h1>Label</h1>
                        <p>Unlimited albums and singles for unlimited artists</p>
                        <h6>From <span>$5.99</span> per month</h6>
                    </div>
                </div>
                <div className='col-md-4 col-6 mt-4'>
                    <div className={`plan-card ${plan === 3 && 'active'}`} onClick={() => setPlan(3)}>
                        <img src={p3} alt=""/>
                        <h1>Collaborator</h1>
                        <p>Collect royalties from your splits. Receive 100%.</p>
                        <h6>Get started for free</h6>
                    </div>
                </div>
            </div>
                </div>
            </div>
            {/* <div className='row mt-5 pt-4'>
                <h2 className='text-light w-100 mx-2'>Choose account type</h2>
                <div className='col-lg-4 col-md-6 mt-4'>
                    <div className={`plan-card ${plan === 1 && 'active'}`} onClick={() => setPlan(1)}>
                        <img src={p1} alt=""/>
                        <h1>Artist</h1>
                        <p>Unlimited albums and singles for one artist</p>
                        <h6>From <span>$2.99</span> per month</h6>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 mt-4'>
                    <div className={`plan-card ${plan === 2 && 'active'}`} onClick={() => setPlan(2)}>
                        <img src={p2} alt=""/>
                        <h1>Label</h1>
                        <p>Unlimited albums and singles for unlimited artists</p>
                        <h6>From <span>$5.99</span> per month</h6>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 mt-4'>
                    <div className={`plan-card ${plan === 3 && 'active'}`} onClick={() => setPlan(3)}>
                        <img src={p3} alt=""/>
                        <h1>Collaborator</h1>
                        <p>Collect royalties from your splits. Receive 100%.</p>
                        <h6>Get started for free</h6>
                    </div>
                </div>
            </div> */}
            <div className='row mt-4'>
                <div className='signup-checks'>
                    <div className="form-check">
                        <input className="form-check-input shadow-none" type="checkbox" value="" id="check1" />
                        <label className="form-check-label" for="check1">
                            I want to receive updated and news
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input shadow-none" type="checkbox" value="" id="check2" />
                        <label className="form-check-label" for="check2">
                            I agree to the terms of use
                        </label>
                    </div>
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-lg-6'>
                    <button className='auth-form-btn'>
                        <span>Signup</span>
                    </button>
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default Signup