import React from 'react'
import down from "../assets/down.svg";


const PrivacyPolicyPage = () => {
  return (
    <>
      <div className="home-banner c-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="banner-text">
                <h1 className="banner-title">Privacy Policy</h1>
                <h3 className="banner-subtitle">
                  This Privacy Policy describes how we collects, uses, and shares information when you use our website <span>banglanewera.com</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='privacy-policy'>
        <div className='container'>
          <h3>Information We Collect</h3>
          <p>
            We may collect various types of information, including but not limited to:
          </p>
          <ul>
            <li>
              <span>Personal Information:</span> This may include your name, email address, phone number, or other personally identifiable information you provide when contacting us or using our services.
            </li>
            <li>
              <span>Log Data:</span> We automatically collect information that your browser sends whenever you visit our website. This may include your computer's Internet Protocol (IP) address, browser type, browser version, the pages you visit, the time and date of your visit, the time spent on those pages, and other statistics.
            </li>
          </ul>
          <h3>How We Use Your Information</h3>
          <p>
            We may use the information we collect for various purposes, including but not limited to:
          </p>
          <ul>
            <li>Providing and maintaining the Service.</li>
            <li>Notifying you about changes to our Service.</li>
            <li>Responding to your inquiries, comments, or questions.</li>
            <li>Sending you promotional and marketing materials if you have opted to receive them.</li>
          </ul>
          <h3>Cookies and Similar Tracking Technologies</h3>
          <p>
            We may use cookies and similar tracking technologies to track the activity on our website and hold certain information. You have the option to accept or refuse these cookies and to know when a cookie is being sent to your computer.
          </p>
          <h3>Disclosure of Your Information</h3>
          <p>
            We do not sell, trade, or rent your personal information to third parties. We may share your information with third-party service providers who assist us in operating our website or providing our services.
          </p>
          <h3>Changes to This Privacy Policy</h3>
          <p>
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
          </p>
          <h3>Contact Us</h3>
          <p>
            If you have any questions or concerns about this Privacy Policy, please contact us at [Your Contact Information].
          </p>

        </div>
      </div>
    </>
  )
}

export default PrivacyPolicyPage