import { React, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import img_playlist from "../assets/Playlist.png";
import img_distribution from "../assets/Distribution.png";
import img_celebration from "../assets/Celebration.png";
import img_play from "../assets/play.png";
import img_recordCircle from "../assets/record-circle.png";
import img_export from "../assets/export.png";
import right from "../assets/right.svg";
import s1 from "../assets/s1.jpg";
import s2 from "../assets/s2.jpg";
import s3 from "../assets/s3.jpg";
// <<<<<<< Updated upstream
import axios from "axios";
// =======
import TextTransition, { presets } from 'react-text-transition';
import SnackBar from "../components/notification";
import { Link } from "react-router-dom";
import { TypeAnimation } from 'react-type-animation';

const TEXTS = ['artists', 'managers', 'labels'];
// >>>>>>> Stashed changes

const HomePage = () => {

    const [index, setIndex] = useState(0);

    useEffect(() => {
      const intervalId = setInterval(
        () => setIndex((index) => index + 1),
        1500, // every 3 seconds
      );
      return () => clearTimeout(intervalId);
    }, []);

  const history = useHistory();
  const cardData = [
    {
      img: img_playlist,
      title: "Curate",
      description:
        "Reach an audience with similar taste in music through curation",
      buttonImg: img_play,
      buttonTitle: "view playlists",
    },
    {
      img: img_distribution,
      title: "Distribute",
      description:
        "Get your music on major platforms and maximize earnings through distribution",
      buttonImg: img_recordCircle,
      buttonTitle: "visit studio",
    },
    {
      img: img_celebration,
      title: "Celebrate",
      description:
        "Showcase your music and connect with your fans IRL through celebration",
      buttonImg: img_export,
      buttonTitle: "view events",
    },
  ];

  const clickCardButton = (title) => {
    if (title === "Curate") history.push("/curate");
    if (title === "Distribute") history.push("/distribute");
    if (title === "Celebrate") window.location.href = "http://bne.live";
  };

  // Signup Panel
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [artistURL, setArtistURL] = useState("");

  const [notificationOpened, setNotificationOpened] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState("");
  const [message, setMessage] = useState("");
  const closeNotification = () => {
    setNotificationOpened(false);
  };

  const createAccount = async () => {
    // if (password !== confirmPassword) {
    //   setNotificationOpened(true);
    //   setNotificationStatus("warning");
    //   setMessage("Confirm password!");
    //   return;
    // }
    axios
      .post(
        "https://sheet.best/api/sheets/2d0493ad-cab7-49dc-bfdd-de629deddc7f",
        { email, name, artistURL }
      )
      .then((res) => {
        if (res.status === 200) {
          setNotificationOpened(true);
          setNotificationStatus("success");
          setMessage("Thank you for showing interest! We'll get back to you shortly!");
          setName('')
          setEmail('')
          setArtistURL('')
        }
      })
      .catch((err) => {
        setNotificationOpened(true);
        setNotificationStatus("error");
        setMessage("Error Occurred!");
      });
  };


  return (
    <div className="home-page">
      {/* <div className="bg"></div>
      <div className="title">Empowering <TextTransition springConfig={presets.stiff} inline>{TEXTS[index % TEXTS.length]}</TextTransition> with tools to 
      succeed</div>
      <div className="subtitle">Your music. Your rules. Get your music heard!</div>
      <div className="cards">
        {cardData.map((item, i) => (
          <div className="card-container" key={i}>
            <img alt="play list" src={item.img} className="card_img" />
            <h2 className="name">{item.title}</h2>
            <div className="description">{item.description}</div>
            <div
              className="button"
              onClick={() => {
                clickCardButton(item.title);
              }}
            >
              <img alt="play" src={item.buttonImg} className="button_img" />
              {item.buttonTitle}
            </div>
          </div>
        ))}
      </div>
      <SnackBar
        opened={notificationOpened}
        status={notificationStatus}
        message={message}
        onClose={closeNotification}
      />
      <div className="d-flex justify-content-center px-4 mt-5 pt-5">
        <div className="signup-panel">
          <h1>Join the waitlist</h1>
          <div className="input-panel">
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Spotify Artist Profile URL"
            value={artistURL}
            onChange={(e) => setArtistURL(e.target.value)}
          />
          <input
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          </div>
          <button className="create-account" onClick={() => createAccount()}>
            Sign Up
          </button>
        </div>
      </div> */}
      <div className="home-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="banner-text">
                <TypeAnimation
                  sequence={[
                    // Same substring at the start will only be typed out once, initially
                    'Empowering Artists',
                    1500, // wait 1s before replacing "Mice" with "Hamsters"
                    'Empowering Labels',
                    1500,
                    'Empowering Managers',
                    1500,
                  ]}
                  wrapper="h1"
                  speed={100}
                  repeat={Infinity}
                  className="banner-title"
                />
                <h1 className="banner-title">with tools to succeed</h1>
                <h3 className="banner-subtitle">Your <span>music</span>. Your <span>rules</span>. Get your music heard!</h3>
                <Link to={'/signup'} className="banner-btn">
                    Get Started
                    <img src={right} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-4 col-md-6 mt-4">
            <div className="s-card" style={{ backgroundImage: `url(${s1})` }}>
              <div className="s-content">
                <h1>Curate</h1>
                <p>Reach an audience with similar taste in music through curation</p>
                <Link to={'/curate'} className='s-btn'>
                    View Playlists
                    <img src={right} alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt-4">
            <div className="s-card" style={{ backgroundImage: `url(${s2})` }}>
              <div className="s-content">
                <h1>Distribute</h1>
                <p>Get your music on major platforms and maximize earnings through distribution</p>
                <Link to={'/distribute'} className='s-btn'>
                    Visit Studio
                    <img src={right} alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt-4">
            <div className="s-card" style={{ backgroundImage: `url(${s3})` }}>
              <div className="s-content">
                <h1>Celebrate</h1>
                <p>Showcase your music and connect with your fans IRL through celebration</p>
                <a href="http://bne.live" className='s-btn'>
                    View Events
                    <img src={right} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <section className="signup-section py-5" id="signup">
        <div className="container">
          <div className="col-lg-6 m-auto">
            <div className="signup-form">
              <h1>Join the Waitlist</h1>
              <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="text"
                placeholder="Spotify Artist Profile URL"
                value={artistURL}
                onChange={(e) => setArtistURL(e.target.value)}
              />
              <input
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button className="create-account" onClick={() => createAccount()}>
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default HomePage;
