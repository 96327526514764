import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import RouteWithLayout from "./components/RouteWithLayout";
import { Layout } from "./layout";
import { AboutUsPage } from "./view/AboutUsPage";
import { ContactUsPage } from "./view/ContactUsPage";
import { DistributePage } from "./view/DistributePage";
import HomePage from "./view/HomePage";
import { CuratePage } from "./view/CuratePage";
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from 'react'
import ScrollToTop from "./components/SrollToTop";
import Signup from "./view/Signup";
import PrivacyPolicyPage from "./view/PrivacyPolicyPage";

const App = () => {

    // const location = useLocation();
    
    // useEffect(() => {
    //     window.scrollTo(0, 0);
    //   },
    // [location])

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <RouteWithLayout component={HomePage} exact path="/" layout={Layout} />
        <RouteWithLayout component={HomePage} path="/home" layout={Layout} />
        <RouteWithLayout
          component={DistributePage}
          path="/distribute"
          layout={Layout}
        />
        <RouteWithLayout
          component={CuratePage}
          path="/curate"
          layout={Layout}
        />
        <RouteWithLayout
          component={AboutUsPage}
          path="/aboutus"
          layout={Layout}
        />
        <RouteWithLayout
          component={ContactUsPage}
          path="/contactus"
          layout={Layout}
        />
        <RouteWithLayout
          component={PrivacyPolicyPage}
          path="/privacypolicy"
          layout={Layout}
          />
        <Route component={ContactUsPage} path="/contactus" />
        <Route component={Signup} path="/signup" />
      </Switch>
    </Router>
  );
};

export default App;
